@import url('https://fonts.googleapis.com/css2?family=Vina+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@500&display=swap');

:root {
    --blue-500: #36495e !important;
    --primary-color: #059552 !important;


    box-shadow: none !important;

}

header,
body {
    font-family: 'Public Sans', 'Vina Sans' !important;
    font-weight: lighter !important;
    margin: 0;
}

.iconTitle i {
    font-size: x-large;
}

.p-component {
    font-family: 'Public Sans', 'Vina Sans' !important;
}

.text-color {
    color: #6c6c6c !important;
}

.p-button-primary {
    border-color: #059552 !important;
    background-color: #059552 !important;
}

.p-button-primary:enabled:hover {
    border-color: #07b564 !important;
    background-color: #07b564 !important;

}

.p-button-secondary {
    border-color: #36495e !important;

}

.p-button-secondary:enabled:hover {
    border-color: #36495e !important;
    background-color: #36495e10 !important;

}

.p-datatable {
    width: 100%;
}

.p-datatable-header {
    border: none !important;

    .table-header {
        display: flex !important;
        width: 100%;
        justify-content: space-between !important;
    }

}

.p-button-label {
    font-weight: lighter !important;
}

.p-datatable-thead {
    border: none !important;

    .p-column-title {
        font-size: 14px;
        font-weight: 600;

    }
}

.p-datatable-thead tr th {
    border: none !important;
    border-bottom: 1px solid #cbcbcb !important;
    background-color: #fff !important;
}

.p-datatable-header {
    background-color: #fff !important;
}

.p-datatable-tbody td {
    font-size: 14px !important;
    padding: 5px !important;
    margin: 0 !important;
}

.p-sortable-column-icon svg {
    width: 10px !important;
    height: 10px !important;
}

.p-paginator-current {
    font-size: 13px;
    color: #6c6c6c;
}

.p-component {
    font-size: 13px !important;
}

.p-paginator-bottom {
    font-size: 13px !important;
    color: #6c6c6c !important;
    border: none !important;
}

.p-button-success,
.p-button-danger {
    width: 30px !important;
    height: 30px !important;
}

.p-button.p-button-text {
    color: #fff !important;
    font-family: 'Public Sans', 'Vina Sans' !important;
    font-size: 16px !important;
    font-weight: lighter !important;
    line-height: 1 !important;

}

.p-paginator-bottom button,
.p-dropdown-label {
    font-size: 14px !important;
}

.p-button {
    background-color: #059552;
}

.p-datatable .p-column-header-content {
    justify-content: center;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: none !important;
}

.p-datatable .p-sortable-column.p-highlight {
    background: #f8f9fa;
    color: #000 !important;
    box-shadow: none !important;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #000 !important;
}

.p-selectbutton .p-button.p-highlight {
    background: #36495e !important;
    border-color: #36495e !important;
    box-shadow: none !important;
}

.p-inputnumber-button {
    background: #36495e !important;
    border-color: #36495e !important;
}

.btn-primary {
    background: #059552 !important;
    border-color: #059552 !important;
}

.p-checkbox .p-checkbox-box.p-highlight,
.p-button-search {
    background: #36495e !important;
    border-color: #36495e !important;
    box-shadow: none !important;
}

.p-button.p-button-success,
.p-buttonset.p-button-success>.p-button,
.p-splitbutton.p-button-success>.p-button,
.p-fileupload-choose.p-button-success,
.p-button.p-button-warning,
.p-buttonset.p-button-warning>.p-button,
.p-splitbutton.p-button-warning>.p-button,
.p-fileupload-choose.p-button-warning {
    color: #fff !important;
    background: #36495e !important;
    border: 1px solid #36495e !important;
}

.p-button.p-button-link {
    color: #36495e !important;
}

.searcherTitle {
    font-size: 16px;
}

.p-datatable .p-datatable-tbody>tr.p-highlight {
    color: #fff !important;
    background: #36495e !important;
    border-color: #36495e !important;
}

.btn-secondary-crud span {
    color: #36495e !important;
    opacity: .8;
    font-size: 13px;
}

.p-checkbox-box:hover {
    border-color: #36495e !important;
    box-shadow: none !important;
}

.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
    box-shadow: none !important;
}

.formHorizontalSeparation {
    width: 20px;
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning>.p-button,
.p-splitbutton.p-button-warning>.p-button,
.p-fileupload-choose.p-button-warning {
    color: #fff;
    background: #36495e;
    border: 1px solid #36495e;
}

.p-fileupload-buttonbar button,
.p-fileupload-buttonbar span,
.p-button .p-fileupload-choose .p-component {
    color: #fff !important;
    background: #36495e !important;
    border: 1px solid #36495e !important;
    box-shadow: none !important;
}