.login {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

}

.p-card {
    width: 450px;
    margin: "auto";
    padding: 10px;
}

h1 {
    text-align: center;
    padding-bottom: 20px;
}

button {
    margin-top: 20px;

}