.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
}

.field {
    padding: 0px 0px 10px 0px !important;
    margin: 0px !important;
}

.formFooter {
    margin-top: 10px;
}

.form-crud {
    min-width: 400px;
    margin-bottom: 10px !important;
}

.btn-secondary-crud,
.btn-primary-crud {
    width: 170px !important;
}